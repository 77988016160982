
import React from 'react'
import { mdx } from '@mdx-js/react'

/* @jsx mdx */
import {
  FullScreenCode,
  Head,
  Image,
  SplitRight,
  themes,
  Steps,
} from "mdx-deck";
import { CodeSurfer } from "code-surfer";
import { vsDark } from "@code-surfer/themes";
import chromaticSite from "./images/chromatic-site.png";
import storyBookSite from "./images/storybook-site.png";
import storyBookSupports from "./images/storybook-supports.png";
export const theme = { ...themes.dark,
  styles: {
    ul: {
      maxWidth: "80vw"
    },
    li: {
      fontSize: "1.4em",
      lineHeight: "1.5em",
      marginBottom: ".6em"
    },
    p: {
      fontSize: "1.3em"
    },
    a: {
      fontSize: ".8em",
      color: "#fff"
    },
    blockquote: {
      width: "60vw",
      fontSize: "1.5em",
      fontStyle: "italic",
      // "&::before": {
      //   fontSize: "3em",
      //   content: "'\"'",
      //   marginBottom: 0,
      //   padding: 0,
      // },
      p: {
        fontSize: "1em"
      }
    },
    CodeSurfer: {
      title: {
        fontSize: "1.5em"
      }
    }
  }
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
      console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope")
      return <div {...props}/>
    };
const Notes = makeShortcode("Notes");
const layoutProps = {
  theme
};
const MDXLayout = "wrapper"
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Head mdxType="Head">
  <title>Storybook Streamlines UI Component Development</title>
    </Head>
    <hr></hr>
    <h1>{`Storybook Streamlines`}</h1>
    <h1>{`UI Component Development`}</h1>
    <h2></h2>
    <h2></h2>
    <h2>{`Steve Schwarz`}</h2>
    <h2><a parentName="h2" {...{
        "href": "https://twitter.com/steveaschwarz"
      }}>{`@steveaschwarz`}</a></h2>
    {
      /*
      ---
      ## Steve Schwarz
      - Developer since 1991
      - Full stack web dev:
       Python (Django, Flask), JavaScript, Angular, VueJS, Ionic
      - Personal dog agility and tech projects:
       [agilitynerd.com](https://www.agilitynerd.com) [tech.agilitynerd.com](https://tech.agilitynerd.com) [googility.com](https://googility.com) [agilitycourses.com](https://agilitycourses.com) [agilitycoursemaster.com](https://agilitycoursemaster.com)
      */
    }
    <hr></hr>
    <h2><a parentName="h2" {...{
        "href": "https://storybook.js.org/"
      }}>{`https://storybook.js.org/`}</a></h2>
    <Image src={storyBookSite} size="contain" style={{
      width: "80vw",
      margin: "0"
    }} mdxType="Image"></Image>
    <hr></hr>
    <blockquote>
      <p parentName="blockquote">{`Storybook is a development environment for UI components. It allows you to browse a component library, view the different states of each component, and interactively develop and test components.`}</p>
    </blockquote>
    <p><a parentName="p" {...{
        "href": "https://github.com/storybookjs/storybook"
      }}>{`https://github.com/storybookjs/storybook`}</a></p>
    <hr></hr>
    <h2>{`Storybook for Most Frameworks`}</h2>
    <Image src={storyBookSupports} size="contain" style={{
      width: "80vw",
      margin: "0"
    }} mdxType="Image"></Image>
    <hr></hr>
    <h1>{`UI Development "Environment"`}</h1>
    <ul>
      <li parentName="ul">{`Visual, interactive browsing of components/states`}</li>
      <li parentName="ul">{`Controls to change/inspect components`}</li>
      <li parentName="ul">{`Generates component documentation`}</li>
      <li parentName="ul">{`Compose Pages/layouts of components`}</li>
      <li parentName="ul">{`Hot reload of components/stories`}</li>
    </ul>
    <hr></hr>
    <h2>{`Safely Installs Into Projects`}</h2>
    <p><inlineCode parentName="p">{`npx sb init`}</inlineCode></p>
    <Notes mdxType="Notes">
      <ul>
        <li parentName="ul">{`Show default Vue project layout`}</li>
        <li parentName="ul">{`Update to 01 tag`}</li>
        <li parentName="ul">{`Discuss src/stories additions`}</li>
      </ul>
    </Notes>
    <hr></hr>
    <h2>{`Run It!`}</h2>
    <p><inlineCode parentName="p">{`yarn storybook`}</inlineCode></p>
    <Notes mdxType="Notes">
      <ul>
        <li parentName="ul">{`yarn storybook`}</li>
        <li parentName="ul">{`Show Button Primary`}<ul parentName="li">
            <li parentName="ul">{`Controls/Actions`}</li>
            <li parentName="ul">{`Docs`}</li>
          </ul></li>
        <li parentName="ul">{`Show Header`}<ul parentName="li">
            <li parentName="ul">{`Show screen controls`}</li>
          </ul></li>
      </ul>
    </Notes>
    <hr></hr>
    <CodeSurfer theme={vsDark} mdxType="CodeSurfer">
      <pre><code parentName="pre" {...{
          "className": "language-js",
          "metastring": "title=\"A Story Defines Component State\" showNumbers",
          "title": "\"A",
          "Story": true,
          "Defines": true,
          "Component": true,
          "State\"": true,
          "showNumbers": true
        }}>{`import Button from "@/components/Button.vue";

export default {
  title: "Example/Button",
  component: Button,
  argTypes: {
    backgroundColor: { control: "color" },
    size: {
      control: { type: "select", options: ["small", "medium", "large"] },
    },
  },
};

// template mapping args to props
const Template = (args) => <Button {...args} />;

// Each story reuses the template with new args

export const Primary = Template.bind({});
Primary.args = { primary: true, label: "Button" };

export const Secondary = Template.bind({});
Secondary.args = { label: "Button" };
`}</code></pre>
      <pre><code parentName="pre" {...{
          "1": true,
          "className": "language-diff",
          "metastring": "1 subtitle=\"Import component under test\"",
          "subtitle": "\"Import",
          "component": true,
          "under": true,
          "test\"": true
        }}>{``}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-diff",
          "metastring": "3:12 subtitle=\"Setup story\"",
          "3:12": true,
          "subtitle": "\"Setup",
          "story\"": true
        }}>{``}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-diff",
          "metastring": "14:15 subtitle=\"Define render template\"",
          "14:15": true,
          "subtitle": "\"Define",
          "render": true,
          "template\"": true
        }}>{``}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-diff",
          "metastring": "17:20 subtitle=\"Primary Story\"",
          "17:20": true,
          "subtitle": "\"Primary",
          "Story\"": true
        }}>{``}</code></pre>
      <pre><code parentName="pre" {...{
          "className": "language-diff",
          "metastring": "22:23 subtitle=\"Secondary Story\"",
          "22:23": true,
          "subtitle": "\"Secondary",
          "Story\"": true
        }}>{``}</code></pre>
    </CodeSurfer>
    <Notes mdxType="Notes">
      <ul>
        <li parentName="ul">{`switch to 02-vueify`}</li>
        <li parentName="ul">{`discuss layout change`}</li>
        <li parentName="ul">{`Show Button component while going through story`}</li>
      </ul>
    </Notes>
    <hr></hr>
    <h1>{`Production Storybook Projects`}</h1>
    <p><a parentName="p" {...{
        "href": "https://react.carbondesignsystem.com/?path=/story/accordion--accordion"
      }}>{`https://react.carbondesignsystem.com/?path=/story/accordion--accordion`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://bbc.github.io/psammead"
      }}>{`https://bbc.github.io/psammead`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://storybook.js.org/docs/react/get-started/examples"
      }}>{`https://storybook.js.org/docs/react/get-started/examples`}</a></p>
    <hr></hr>
    <h1>{`Stories Can Drive Other Tests`}</h1>
    <ul>
      <li parentName="ul">{`Unit`}</li>
      <li parentName="ul">{`Snapshot`}</li>
      <li parentName="ul">{`Visual Regression`}</li>
      <li parentName="ul">{`End to End`}</li>
    </ul>
    <hr></hr>
    <h1>{`Unit Tests`}</h1>
    <p>{`Use story configurations within unit tests`}</p>
    <hr></hr>
    <h1>{`Snapshot Testing`}</h1>
    <p>{`Use Jest snapshots within unit tests`}</p>
    <p>{`Use `}<inlineCode parentName="p">{`storyshots`}</inlineCode>{` plugin to generate snapshots for `}<em parentName="p">{`all Stories`}</em></p>
    <h2><a parentName="h2" {...{
        "href": "https://storybook.js.org/docs/vue/workflows/snapshot-testing"
      }}>{`https://storybook.js.org/docs/vue/workflows/snapshot-testing`}</a></h2>
    <hr></hr>
    <h1>{`Accessibility Testing`}</h1>
    <p><inlineCode parentName="p">{`storybook-addon-a11y`}</inlineCode>{` uses `}<a parentName="p" {...{
        "href": "https://github.com/dequelabs/axe-core"
      }}>{`axe`}</a></p>
    <h2><a parentName="h2" {...{
        "href": "https://github.com/storybookjs/storybook/tree/master/addons/a11y"
      }}>{`https://github.com/storybookjs/storybook/tree/master/addons/a11y`}</a></h2>
    <p>{`Could also add `}<inlineCode parentName="p">{`axe`}</inlineCode>{` to your Jest tests:`}</p>
    <p><a parentName="p" {...{
        "href": "https://github.com/nickcolley/jest-axe"
      }}>{`https://github.com/nickcolley/jest-axe`}</a></p>
    <hr></hr>
    <h2>{`Visual Regression Testing`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.chromatic.com/choose/visual-testing"
      }}>{`https://www.chromatic.com/choose/visual-testing`}</a></p>
    <Image src={chromaticSite} size="contain" style={{
      height: "60vw",
      margin: "0"
    }} mdxType="Image"></Image>
    <hr></hr>
    <h1>{`Resources`}</h1>
    <h2>{`Learning Storybook`}</h2>
    <p><a parentName="p" {...{
        "href": "https://www.learnstorybook.com/intro-to-storybook/"
      }}>{`https://www.learnstorybook.com/intro-to-storybook/`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://www.learnstorybook.com/design-systems-for-developers"
      }}>{`https://www.learnstorybook.com/design-systems-for-developers`}</a></p>
    <hr></hr>
    <h1>{`Thanks!`}</h1>
    <p>{`@steveaschwarz`}</p>
    <p>{`Code: `}<a parentName="p" {...{
        "href": "https://github.com/saschwarz/vue2-storybook"
      }}>{`https://github.com/saschwarz/vue2-storybook`}</a></p>
    <p>{`Slides: `}<a parentName="p" {...{
        "href": "https://github.com/saschwarz/vue2-storybook-lightning"
      }}>{`https://github.com/saschwarz/vue2-storybook-lightning`}</a></p>
    <hr></hr>
    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;